import React from "react";
import {Typography, Button as CustomButton, useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

const PrimaryButton = (props) => {
  const {large, width, padding, margin, textVariant, rounded, disabled, variant, ...rest} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  let backGroundColor = "#f0a81a"
  if (variant === "outlined" || disabled) {
    backGroundColor = "";
  }

  let borderColor = ""
  if (variant === "outlined" && !disabled) {
    borderColor = "1px solid #f0a81a";
  }

  return (
    <>
      <CustomButton
        variant={!!variant ? variant : "contained"}
        style={{
          textTransform: "none",
          lineHeight: 1.5,
          backgroundColor: backGroundColor,
          "&:hover": {
            backgroundColor: "#f4a507",
          },
          borderRadius: props.rounded ? "33px" : "11px",
          border: borderColor,
          width:
            large && !isMobile
              ? "26rem"
              : width
                ? width
                : "100%",
          padding: !padding ? "14px" : padding,
          margin: margin ? margin : padding ? padding : null,
        }}
        disabled={disabled}
        {...rest}
      >
        <Typography
          variant={!textVariant ? "h6" : textVariant}
          className="font-jost text-primary"
          component="span"
        >
          {props.name}
        </Typography>
      </CustomButton>
    </>
  );
};

export default PrimaryButton;
