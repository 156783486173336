import React from "react";
import {
  AddBoxOutlined,
  IndeterminateCheckBoxOutlined,
} from "@material-ui/icons";
import {Box, Divider, Grid, Typography, Container} from "@material-ui/core";
import {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import * as path from "../../../constants/routes"

const Faq = () => {
  const [showAnswer, setShowAnswer] = useState("01");
  const FAQContent = [
    {
      id: "01",
      question:
        "How does this work? How can we trade credits from solar systems?",
      answer: <>
        The Solar Equity Platform is a software platform that enables its users to receive, or donate metering credits.
        We work with individual homeowners, businesses, solar installers, community development corporations and real
        estate developers to join our peer-to-peer solar network. The software was built off of architecture from
        MySunBuddy, which has supported peer-to-peer sales of net metering credits for over five years.
        <div className="mt-1">
          The Solar Equity Platform is in a pilot phase with institutional partners. These could include solar
          installers, community development corporations or other such entities.
        </div>
      </>,
    },
    {
      id: "02",
      question:
        "Are you a retail electricity provider or with my city’s community choice energy program (CCE)?",
      answer: <>
        No, we are not a retail electricity provider or a CCE. This is a partnership between two private companies
        focused on solar energy. This program will not change your retail electricity provider. If you are a Clean
        Energy Consumer, it will provide discounts on the electricity bills that you currently receive from your
        utility.
      </>
    },
    {
      id: "03",
      question: "How do I receive or donate solar through the platform?",
      answer: <>
        During the sign-up process, we will request access to your energy usage through your utility bill history and
        will also establish a payment method for receiving or sending payments to other peers on the network. The
        platform uses secure third-party providers to complete this process.
        <div className="mt-1">
          Once a match is made and after utility approval, if you are a Clean Energy Consumer you will begin receiving
          net
          metering bill credits. As a Clean Energy Producer, you will receive monthly updates on the impact of your
          donation and the impact of the entire community campaign. This process usually takes approximately one month
          once a match is confirmed.
        </div>
        <div className="mt-1">
          Please note: This pilot is only currently available in Massachusetts. If you are within another state, please
          contact us directly: <a href="mailto:info@mysunbuddy.com"
                                  className="text-secondary no-underline">info@mysunbuddy.com</a>
        </div>
      </>
    },
    {
      id: "04",
      question:
        "As a Clean Energy Producer, can I deduct the energy donations on my taxes?",
      answer: <>
        We would advise you to consult with your tax professional. IRS guidance is not clear on if bill credit donations
        qualify as tax deductible.
      </>,
    },
    {
      id: "05",
      question:
        "How do I know if I am income eligible to be a Clean Energy Consumer?",
      answer: <>
        We are currently working with partners to run community campaigns. These partners will qualify income-eligible
        households who will receive a unique code for accessing the donation-portion of the software. This
        income-eligibility will be based on if you are receiving a discounted utility rate or government assistance.
      </>,
    },
    {
      id: "06",
      question:
        "How long am I matched on the platform? What if I have to move?",
      answer: <>
        Matches for donations are for a minimum of six months. If you would not like to continue after the six-month
        period, you move, or your circumstances change, we can find another match for you or remove you from our network
        if you have moved out of Massachusetts, our service territory. Please just send us a notification at
        <a href="mailto:info@mysunbuddy.com" className="text-secondary no-underline ml-1">info@mysunbuddy.com</a>.
        <div className="mt-1">
          You can find more information about terms under the site's
          <Link to={path.TOS} className="text-secondary no-underline ml-1">Terms and Conditions.</Link>
        </div>
      </>,
    },
    {
      id: "07",
      question:
        "As a  Clean Energy Consumer, will I get utility bill credits every month?",
      answer: <>
        Renewable energy production is variable. During the summer, solar systems produce more energy and therefore will
        have more to donate during the summer. In the winter, system production will be lower and thus the donation
        amount will be less. If it is a particularly cold winter month and the Clean Energy Producer has to use more
        energy than normal to keep their home or business heated, there may not be donation credits in that month. Given
        this, we cannot currently guarantee a specific level of donation on a month-to-month basis, but we can say that
        donations will fluctuate seasonally.
        We hope to address this issue in the long-run with alternative models.
      </>,
    },
    {
      id: "08",
      question:
        "How will I know that my credits were received/that I have received credits?",
      answer: <>
        The platform works through net metering. For consumers, you will receive a bill credit on your utility bill
        based on the amount of solar production a clean energy creator sent you for the month. This will reduce the
        amount you have to pay your electric utility. For creators, you will receive updates on your dashboard on your
        cumulative donation impact. Month-to-month your utility bill will also show a line item for the utility bill
        credit transfer to another party.
      </>,
    },
    {
      id: "09",
      question:
        "How much does the platform cost?",
      answer: <>
        For individual users, it is free to sign-up on the platform.
        <div className="mt-1">
          Solar installers, community development corporations and other project partners pay a fee to use the platform
          to support campaigns in communities.
        </div>
      </>,
    },
  ];

  const showAnswerEvent = (event, id) => {
    setShowAnswer(id)
  }

  const hideAnswerContent = (event) => {
    setShowAnswer("")
  }
  return (
    <>
      <Box className="bg-[#F5F6F8] py-20">
        <Container>
          <Box spacing={4}>
            <Typography
              variant="h4"
              align="left"
              className="text-heading font-jost font-bold mb-8"
            >
              Frequently Asked Questions
            </Typography>
            {FAQContent.map((item, index) => (
              <Fragment key={index}>
                <Grid container direction="row" className="py-8">
                  <Grid item md={1} xs={1} align="left">
                    <Typography
                      variant="body1"
                      className="text-subheading font-open-sans opacity-40 font-bold"
                    >
                      {item.id}
                    </Typography>
                  </Grid>
                  <Grid item md={10} xs={10}>
                    <Box spacing={3}>
                      <Typography
                        variant="body1"
                        align="left"
                        className="text-heading font-open-sans"
                      >
                        {item.question}
                      </Typography>
                      {showAnswer === item.id ? (
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className="text-subheading font-open-sans pt-4"
                        >
                          {item.answer}
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item md={1} xs={1} align="right">
                    {showAnswer === item.id ? (
                      <IndeterminateCheckBoxOutlined
                        className="text-gray-500 cursor-pointer"
                        onClick={(event) => hideAnswerContent(event)}
                      />
                    ) : (
                      <AddBoxOutlined
                        className="text-gray-500 cursor-pointer"
                        onClick={(event) => showAnswerEvent(event, item.id)}
                      />
                    )}
                  </Grid>
                </Grid>
                {FAQContent.length > index + 1 ? (
                  <Divider/>
                ) : null}
              </Fragment>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Faq;
