import { SET_PENDING_DEALS, SET_ACTIVE_DEALS, SET_TRANSACTIONS } from "../actions/deals";

const initState = {
  pending_deals: null,
  active_deals: null,
  transactions: null,
};

export const reducerDeals = (state = initState, action) => {
  switch (action.type) {
    case SET_PENDING_DEALS: {
      return { ...state, pending_deals: action.payload };
    }
    case SET_ACTIVE_DEALS: {
      return { ...state, active_deals: action.payload };
    }
    case SET_TRANSACTIONS: {
      return { ...state, transactions: action.payload };
    }
    default: {
      return state;
    }
  }
};
