import React, {Component, lazy, Suspense} from "react";
import {withRouter, Switch, Route} from "react-router";
import * as path from "../src/constants/routes";
import Main from "./components/Main";
import Loader from "./components/Loader";
import Login from "./pages/Login/index";
import SignUp from "./pages/SignUp/index";
import HomePage from "./pages/HomePage";
import Privacy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import StripeRefreshUrl from "./pages/StripeRefreshUrl/StripeRefreshUrl";
import ResetPassword from "./pages/ResetPassword";
import UtilityApiCallback from "./pages/UtilityApiCallback/UtilityApiCallback";
import ForgotPassword from "./pages/ForgotPassword/forgotPassword";
import PageNotFound from "./components/PageNotFound";
import "./App.css";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Profile = lazy(() => import("./pages/Profile"));

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact from={"/"} to={path.HOME} component={HomePage}/>
        <Route exact path={path.SIGN_IN} component={Login}/>
        <Route exact path={path.PRIVACY} component={Privacy}/>
        <Route exact path={path.TOS} component={TermsAndConditions}/>
        <Route exact path={path.SIGN_UP} component={SignUp}/>
        <Route exact path={path.FORGOT_PASSWORD} component={ForgotPassword}/>
        <Route
          exact
          path={path.STRIPE_REFRESH_URL}
          component={StripeRefreshUrl}
        />
        <Route exact path={path.RESET_PASSWORD} component={ResetPassword}/>
        <Route
          exact
          path={path.UTILITY_API_CALLBACK}
          component={UtilityApiCallback}
        />
        <Main {...this.props}>
          <Suspense fallback={<Loader/>}>
            <Switch>
              <Route
                exact
                path={path.DASHBOARD}
                component={Dashboard}
              />
              <Route
                exact
                path={path.PROFILE}
                render={(props) => <Profile {...props} />}
              />
              <Route render={(props) => <PageNotFound {...props} />}/>
            </Switch>
          </Suspense>
        </Main>
      </Switch>
    );
  }
}

export default withRouter(App);
