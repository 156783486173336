import React, {Fragment} from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {Container} from "@material-ui/core";
import {useTheme} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";

import PrimaryButton from "../../FormComponents/PrimaryButton";
import Divider from "../LayoutComponents/Divider";

import SectionImage from "../../../assets/HomePage/Rectangle9.png";
import {useHistory} from "react-router-dom";
import {SIGN_UP} from "../../../constants/routes";

const WorkWithUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const paperContents = [
    {
      score: 2313,
      text: "variable",
    },
    {
      score: 2313,
      text: "variable",
    },
    {
      score: 2313,
      text: "variable",
    },
    {
      score: 2313,
      text: "variable",
    },
  ];

  const history = useHistory()

  const goToSignUp = () => {
    history.push(SIGN_UP)
  }

  return (
    <>
      <Container className={isMobile ? "" : "pb-20"} id="service-section">
        <Box className="relative" sx={{paddingY: "8rem"}}>
          <Grid container spacing={5} justifyContent="space-between">
            <Grid item md={6} xs={12} data-aos="fade-right" align="left">
              <img src={SectionImage} alt="logo" width="100%"/>
            </Grid>
            <Grid item md={6} xs={12} className={isMobile ? "" : "pl-0"}>
              <Box className={isMobile ? "" : "ml-12"}>
                <Divider className="mb-8"></Divider>
                <Box>
                  <Typography
                    variant="h4"
                    className="font-jost text-heading font-bold w-3/5 leading-normal mb-8"
                    align="left"
                  >
                    Do you want to work with us?
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  className="font-open-sans text-subheading mb-8 leading-loose"
                  align="left"
                  component="div"
                >
                  <Box component="div" className="pl-1">
                    <div>
                      The Solar Equity Platform works with partners to facilitate community-level campaigns to match
                      Clean
                      Energy Producers and Consumers. We share the environmental and economic impact of the collective
                      effort with Clean Energy Producers and Consumers in the campaign network and provide
                      enterprise-level
                      controls for campaign hosts.
                    </div>
                    <div>
                      We would love to work with solar installers, community development corporations and others to
                      create
                      localized campaigns to support solar energy. Our current partners include: Codman Square
                      Neighborhood
                      Development Corporation and All in Energy.
                    </div>
                    <div>
                      If you currently live in one of our partners’ territories and believe you would qualify as a Clean
                      Energy Consumer, please contact Resonant Energy for further information.
                    </div>
                    <div>
                      If you are interested in setting up a donation campaign in your neighborhood, please reach out to:
                      sep@resonant.energy
                    </div>
                  </Box>
                </Typography>
                <Box align="left">
                  <PrimaryButton
                    name={
                      <Box className="flex items-start">
                        Register now
                        <ArrowForward
                          style={{
                            height: "15px",
                            paddingTop: "3px",
                          }}
                        />
                      </Box>
                    }
                    width="12rem"
                    textVariant="body2"
                    onClick={goToSignUp}
                  ></PrimaryButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {false && (
            <Box
              style={{
                position: isMobile ? null : "absolute",
                bottom: 0,
                right: 0,
                marginTop: isMobile ? "3rem" : "",
              }}
              data-aos="zoom-in"
            >
              <Paper elevation={5} style={{borderRadius: "16px"}}>
                <Grid container direction="row">
                  {paperContents.map((item, index) => (
                    <Fragment key={index}>
                      <Grid item md={3} xs={12} className="py-8 px-16">
                        <Box>
                          <Typography
                            variant="h4"
                            className="font-jost text-heading"
                            style={{fontWeight: "700"}}
                            component="div"
                          >
                            {item.score}
                          </Typography>
                          <Typography
                            variant="body1"
                            className="font-open-sans text-subheading"
                            component="div"
                          >
                            {item.text}
                          </Typography>
                        </Box>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Paper>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};
export default WorkWithUs;
