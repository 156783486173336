import axios from "axios";
import { LOADING_TRUE, LOADING_FALSE } from "./toggleLoader";
import * as API_URL from "../apiUrls/apiUrls";

export const LOGIN = "login";

export const login = (values, setFieldError, moveTo) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    let token;
    axios
      .post(API_URL.LOGIN, { ...values })
      .then((response) => {
        token = response.data.token;
        localStorage.setItem("token", token);
        dispatch({
          type: LOGIN,
          payload: response.data.user,
        });
        if (
          response.data.user.customer_type === "SELLER" &&
          response.data.user.payment_api_key
        ) {
          axios({
            method: "get",
            url: API_URL.CHECK_PAYOUTS_ENABLED,
            headers: {
              Authorization: `Token ${token}`,
            },
          })
            .then((resp) => {
              return resp.data === "Payouts enabled" ? true : false;
            })
            .then((value) => {
              moveTo(response.data.user, value);
            });
        } else {
          moveTo(response.data.user);
        }
      })
      .catch((error) => {
        for (var key in error.response.data) {
          setFieldError(key, error.response.data[key]);
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const getProfile = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    let token = localStorage.getItem("token");
    if (token) {
      axios({
        method: "get",
        url: API_URL.GET_PROFILE,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          dispatch({
            type: LOGIN,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            dispatch(logout());
            window.location.href = "/login";
          }
        });
    } else {
      window.location.href = "/login";
    }
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const UPDATE_PROFILE = "update_profile";

export const updateProfile = (newProfile) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    dispatch({
      type: UPDATE_PROFILE,
      payload: newProfile,
    });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const LOGOUT = "logout";

export const logout = (moveTo) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: API_URL.LOG_OUT,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        localStorage.removeItem("token");
        dispatch({
          type: LOGOUT,
          payload: null,
        });
        moveTo();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const REGISTER_USER = "register_user";

export const registerUser = (values, setFieldError, moveTo) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    let url =
      values.customer_type === "seller"
        ? API_URL.SIGN_UP_SELLER
        : API_URL.SIGN_UP_BUYER;
    axios
      .post(url, {
        username: values.username,
        email: values.email,
        phone_number: values.phone_number,
        load_zone: "NEMA_BOS",
        utility_zone: values.utility_zone,
        seller_code: values.seller_code,
        password: values.password,
        password_confirm: values.password_confirm,
        address: values.address,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.auth_token);
        dispatch({
          type: LOGIN,
          payload: response.data,
        });
        moveTo();
      })
      .catch((error) => {
        for (var key in error.response.data) {
          setFieldError(key, error.response.data[key]);
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const editUser = (values, setFieldError, callback) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    axios({
      method: "put",
      url: API_URL.EDIT_USER,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      data: {
        ...values,
      },
    })
      .then((response) => {
        dispatch({
          type: LOGIN,
          payload: response.data,
        });
        if (response.status === 200) {
          callback();
        }
      })
      .catch((error) => {
        for (var key in error.response.data) {
          setFieldError(key, error.response.data[key]);
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const applyDiscountCode = (values, setError) => {
  return (dispatch) =>
    axios({
      method: "post",
      url: API_URL.APPLY_DISCOUNT_CODE,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      data: values,
    })
      .then((response) => {
        dispatch({
          type: UPDATE_PROFILE,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log("error", error.response);
        setError(error.data.code);
      });
};
