import { GET_SELLER_CODES } from "../actions/sellerCodes";
const initState = {
  data: [],
};

export const reducerSellerCodes = (state = initState, action) => {
  switch (action.type) {
    case GET_SELLER_CODES: {
      return { ...state, data: action.payload.data };
    }
    default: {
      return state;
    }
  }
};
