import React, {useState} from "react";
import {
  AppBar,
  Typography,
  Box,
  Grid,
  Container,
  Menu,
  MenuItem, Divider, styled,
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import * as path from "../../../constants/routes";
import Avatar from "@material-ui/core/Avatar";

import {deepPurple} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {logout} from "../../../store/actions/profile";
import ModalChangePassword from "../../Modals/ModalChangePassword";


const useStyles = makeStyles((theme) => ({
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));


const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    borderRadius: "0 0 16px 16px !important"
  }
})

const getAvatarName = (username) => {
  try {
    const splittedName = username.split(" ")
    const firstLetter = splittedName[0][0].toUpperCase()
    const secondLetter = splittedName[1][0].toUpperCase()
    return firstLetter + secondLetter
  } catch (AttributeError) {
    return username
  }
}

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile.profile);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);


  const handleClose = () => {
    setAnchorEl(null);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const redirect = (path) => {
    handleClose()
    history.push(path)
  }

  const handleLogout = () => {
    let moveTo = () => {
      window.location.href = "/login";
    };
    dispatch(logout(moveTo));
  };

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
  };

  const isProfilePage = history.location.pathname === path.PROFILE
  const isDashboardPage = history.location.pathname === path.DASHBOARD

  return (
    <>
      <ModalChangePassword
        open={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
        userId={profile.id}
      />
      <AppBar component="nav" elevation={0} className="bg-white" position="sticky">
        <Container>
          <Grid
            container
            direction="row"
            className="w-full justify-between py-3"
          >
            <Typography
              variant="h4"
              className="text-subheading font-jost font-bold cursor-pointer"
              component="div"
              onClick={() => history.push(path.DASHBOARD)}
            >
              SEP
            </Typography>
            <Box component="div" className="cursor-pointer flex items-center gap-1" onClick={handleClick}>
              <Avatar className={classes.purple}>
                {getAvatarName(profile.username)}
              </Avatar>
              <ExpandMoreIcon className="text-subheading"/>
            </Box>
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              className="mt-0 md:mt-3 md:ml-10 rounded-lg"
            >
              <Box className="p-2 w-64">

                {!isProfilePage && (
                  <>
                    <MenuItem onClick={() => redirect(path.PROFILE)} className="p-0">
                      <Box className="flex items-center gap-2 pb-5 pt-3 px-2">
                        <AccountCircleOutlinedIcon className="text-subheading" fontSize="small"/>
                        <Typography variant="subtitle2" className="font-jost text-subheading">
                          My profile
                        </Typography>
                      </Box>
                    </MenuItem>
                  </>
                )}

                {!isDashboardPage && (
                  <>
                    <MenuItem onClick={() => redirect(path.DASHBOARD)} className="p-0">
                      <Box className="flex items-center gap-2 pb-5 pt-5 px-2">
                        <DashboardIcon className="text-subheading" fontSize="small"/>
                        <Typography variant="subtitle2" className="font-jost text-subheading">
                          Dashboard
                        </Typography>
                      </Box>
                    </MenuItem>
                  </>
                )}

                <Divider className="px-2 m-auto bg-[#6D6D6D]"/>
                <MenuItem onClick={handleOpenChangePasswordModal} className="p-0">
                  <Box className="flex items-center gap-2 pb-5 pt-5 px-2">
                    <LockOutlinedIcon className="text-subheading" fontSize="small"/>
                    <Typography variant="subtitle2" className="font-jost text-subheading">
                      Change Password
                    </Typography>
                  </Box>
                </MenuItem>

                <Divider className="px-2 m-auto bg-[#6D6D6D]"/>
                <MenuItem onClick={handleLogout} className="p-0">
                  <Box className="flex items-center gap-2 pb-3 pt-5 px-2">
                    <ExitToAppOutlinedIcon style={{color: "#FF314A"}} fontSize="small"/>
                    <Typography variant="subtitle2" className="font-jost text-[#FF314A]">
                      Logout
                    </Typography>
                  </Box>
                </MenuItem>
              </Box>
            </StyledMenu>
          </Grid>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
