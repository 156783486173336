import axios from "axios";
import * as API_URL from "../apiUrls/apiUrls";
import { LOADING_TRUE, LOADING_FALSE } from "./toggleLoader";
export const SET_PENDING_DEALS = "set_pending_deals";
export const SET_ACTIVE_DEALS = "set_active_deals";
export const SET_TRANSACTIONS = "set_transactions";

export const getValidDeals = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    axios({
      method: "get",
      url: API_URL.GET_PENDING_DEALS,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        dispatch({
          type: SET_PENDING_DEALS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const acceptDeal = (values, callback) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    axios({
      method: "post",
      url: API_URL.ACCEPT_DEAL,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      data: values,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(getValidDeals());
          dispatch(getActiveDeals());
          callback();
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const getActiveDeals = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    axios({
      method: "get",
      url: API_URL.GET_ACTIVE_DEALS,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        dispatch({
          type: SET_ACTIVE_DEALS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};

export const getTransactions = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING_TRUE,
      payload: true,
    });
    axios({
      method: "get",
      url: API_URL.GET_LIST_TRANSACTION,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        dispatch({
          type: SET_TRANSACTIONS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log("error", error.response);
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
    dispatch({
      type: LOADING_FALSE,
      payload: false,
    });
  };
};
