import React from "react";
import { isAuth } from "../../hoc/isAuth";
import { makeStyles } from "@material-ui/core/styles";
import UserCompletedSteps from "../Stepper/Stepper";

import Header from "../Dashboard/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "64px",
    justifyContent: "flex-end",
  },
  content: {
    width:"100%",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentShift: {
    marginLeft: 0,
  },
}));

const Main = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <main className="bg-[#F6F6F6] h-[calc(100vh-66px)]">
        <div className={classes.drawerHeader} />
        <UserCompletedSteps />
        <div className="mt-16">
          {props.children}
        </div>
      </main>
    </div>
  );
};

export default isAuth(Main);
