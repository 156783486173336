export const HOME = "/";
export const SIGN_IN = "/login";
export const SIGN_UP = "/signup";
export const PRIVACY = "/privacy";
export const TOS = "/tos";
export const FORGOT_PASSWORD = "/forgot-password";
export const DASHBOARD = "/dashboard";
export const PROFILE = "/profile";
export const CONFIRM_EMAIL = "/confirm-email";
export const RESET_PASSWORD = "/reset-password";
export const UTILITY_API_CALLBACK = "/utility_api_callback";
export const STRIPE_REFRESH_URL = "/stripe_refresh_url";
