import React from "react";
import {styled} from "@material-ui/core/styles";
import {Divider as Divder} from "@material-ui/core";

const CustomDivider = styled(Divder)({
  borderTop: "7px solid #f0a81a",
  width: "5rem",
});

const Divider = (props) => {
  return (
    <>
      <CustomDivider className={props.className}></CustomDivider>
    </>
  );
};

export default Divider;
