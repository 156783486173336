import React, {useState, useCallback} from "react";
import axios from "axios";
import * as API_URL from "../../store/apiUrls/apiUrls";
import * as path from "../../constants/routes";
import Box from "@material-ui/core/Box";
// import ForgotPasswordForm from "../../components/Forms/ForgotPasswordForms/ForgotPasswordForm";
// import SuccessSendInstruction from "../../components/Forms/ForgotPasswordForms/SuccessSendInstruction";

import ForgotPasswordComponent from "../../components/ForgotPassword";
import SendInstruction from "../../components/SendInstruction";

const ForgotPassword = (props) => {

  const [success, setSucces] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [disabled, setDisable] = useState(false);

  const sendInstruction = (values) => {
    setUserEmail(values.email);
    axios({
      method: "post",
      url: API_URL.FORGOT_PASSWORD,
      data: {email: values.email},
    })
      .then((response) => {
        if (response.status === 200) {
          setSucces(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
  };

  const checkCode = useCallback(
    (values, {setErrors}) => {
      let code = values.item1 + values.item2 + values.item3 + values.item4;
      axios({
        method: "post",
        url: API_URL.VERIFY_CODE,
        data: {email: userEmail, code: code},
      })
        .then((response) => {
          if (response.status === 200) {
            props.history.push(path.RESET_PASSWORD, {
              code: code,
              userId: response.data,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.href = "/login";
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, userEmail]
  );

  const debounce = (callback, delay) => {
    return (...args) => {
      callback(...args);
      setTimeout(() => {
        setDisable(false);
      }, delay);
    };
  };

  const resendEmail = debounce(() => {
    setDisable(true);
    axios({
      method: "post",
      url: API_URL.FORGOT_PASSWORD,
      data: {email: userEmail},
    })
      .then((response) => {
        if (response.status === 200) {
          setSucces(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
  }, 3000);

  return (
    <Box>
      {success ? (
        <SendInstruction
          resendEmail={resendEmail}
          disabled={disabled}
          checkCode={checkCode}
        />
      ) : (
        <ForgotPasswordComponent sendInstruction={sendInstruction}/>
      )}
    </Box>
  );
};

export default ForgotPassword;
