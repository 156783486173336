export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const REDIRECT_UTILITY_URL = process.env.REACT_APP_REDIRECT_UTILITY_URL;
export const MIN_PASSWORD_LENGTH = 8;
export const CUSTOMER_TYPE = {
  seller: "SELLER",
  buyer: "BUYER",
};
export const DEAL_STATUSES = {
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  TERMINATED: "Terminated",
};

export const PAYMENT_STATUSES = {
  SUBMITTED: "Submitted",
  PENDING_ADMIN_APPROVAL: "Pending admin approval",
  PENDING_ADMIN_SUBMITTAL: "Pending admin submittal",
  SCHEDULED: "Scheduled",
  PAID: "Paid",
};
