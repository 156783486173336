import React from "react";
import {TextField as Input, InputAdornment} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";

const CustomTextField = styled(Input)({
  "& .MuiInputBase-root": {
    backgroundColor: "#F6F6F6"
  },
  borderRadius: "16px",
  "& .MuiOutlinedInput-root": {
    "border-radius": "12px",
    "padding": "6px 16px",
    "& .MuiSvgIcon-root": {
      fill: "#6D6D6D"
    },
    "& fieldset": {
      borderColor: "#ffffff",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffffff",
    },
    "& .Mui-error": {
      color: "red",
      textAlign: "left"
    },
  },
});
const TextField = (props) => {
  return (
    <>
      <CustomTextField
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{props.icon}</InputAdornment>
          ),
        }}
        {...props}
      />
    </>
  );
};

export default TextField;
