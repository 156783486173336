import React, { useEffect } from "react";
import * as path from "../constants/routes";
import { withRouter } from "react-router";
import Loader from "../components/Loader/index";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../store/actions/profile";

export const isAuth = (Component) => {
  const PrivateRoute = (props) => {
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile.profile);
    useEffect(() => {
      if (!profile) {
        dispatch(getProfile());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let token = localStorage.getItem("token");
    
    if (token) {
      if (!profile) {
        return (
          <Loader
            styles={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        );
      } else {
        return <Component {...props} />;
      }
    } else {
      props.history.push(path.SIGN_IN);
      return null;
    }
  };
  return withRouter(PrivateRoute);
};
