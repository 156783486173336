import React from "react";

import {Grid, Container} from "@material-ui/core";

import Header from "../../components/HomePage/Header";
import Welcome from "../../components/HomePage/Welcome";
import Feature from "../../components/HomePage/Feature";
import PictureSection from "../../components/HomePage/PictureSection";
import InfoSection from "../../components/HomePage/InfoSection";
import WorkWithUs from "../../components/HomePage/WorkWithUs";
import Faq from "../../components/HomePage/Faq";
import Footer from "../../components/HomePage/Footer";

import AOS from "aos";
import "aos/dist/aos.css";


const Home = (props) => {

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <>
      <Header></Header>
      <Grid container>
        <Grid item md={12} sm={12}>
          <Container>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              className="my-12"
            >
              <Welcome/>
              <Feature/>
            </Grid>
          </Container>
        </Grid>
        <Grid item md={12} sm={12}>
          <PictureSection/>
        </Grid>
        <Grid item md={12} sm={12}>
          <InfoSection/>
        </Grid>
        <Grid item md={12} sm={12}>
          <WorkWithUs/>
        </Grid>
        <Grid item md={12} sm={12}>
          <Faq/>
        </Grid>
        <Grid item md={12} sm={12}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
