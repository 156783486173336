import {Box, Container, Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";
import * as path from "../../../constants/routes"

const data = [
  {
    title: "Introduction",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              <>
                MySunBuddy, Inc. (<strong>"Company"</strong> or
                <strong>"We"</strong>) respect your privacy and are committed to
                protecting it through our compliance with this policy on our
                website {LinkText("www.mysunbuddy.com", path.HOME)} and branch
                websites using our codebase, such as the Solar Equity Platform .
              </>
            )}
          </Box>
          <Box>
            {Text(
              <>
                This policy describes the types of information we may collect
                from you or that you may provide when you visit the website
                mysunbuddy.com (our <strong>"Website"</strong>) and our
                practices for collecting, using, maintaining, protecting, and
                disclosing that information.
              </>
            )}
          </Box>
          <Box>
            {Text("This policy applies to information we collect:")}
            {List([
              "On this Website.",
              "In email, text, and other electronic messages between you and this Website.",
              "Through mobile and desktop applications you download from this Website, which provide dedicated non-browser-based interaction between you and this Website.",
            ])}
          </Box>
          <Box>
            {Text("It does not apply to information collected by:")}
            {List([
              "us offline or through any other means, including on any other website operated by Company or any third party (including our affiliates and subsidiaries); or",
              "any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website.",
            ])}
          </Box>
          <Box>
            {Text(
              <>
                "Please read this policy carefully to understand our policies
                and practices regarding your information and how we will treat
                it. If you do not agree with our policies and practices, your
                choice is not to use our Website. By accessing or using this
                Website, you agree to this privacy policy. This policy may
                change from time to time (see{" "}
                {LinkText("Changes to Our Privacy Policy", path.PRIVACY)}). Your
                continued use of this Website after we make changes is deemed to
                be acceptance of those changes, so please check the policy
                periodically for updates."
              </>
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "Children Under the Age of 13",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              <>
                Our Website is not intended for children under 18 years of age.
                No one under age 18 may provide any personal information on the
                Website. We do not knowingly collect personal information from
                children under 18. If you are under 18, do not use or provide
                any information on this Website or on or through any of its
                features/register on the Website, make any purchases through the
                Website, use any of the interactive or public comment features
                of this Website or provide any information about yourself to us,
                including your name, address, telephone number, email address,
                or any screen name or user name you may use. If we learn we have
                collected or received personal information from a child under 18
                without verification of parental consent, we will delete that
                information. If you believe we might have any information from
                or about a child under 18, please contact us at
                {LinkText("info@mysunbuddy.com", path.HOME)}
                with subject line “Potential Underage User.”
              </>
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "Information We Collect About You and How We Collect It",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "We collect several types of information from and about users of our Website, including information:"
            )}
            {List([
              <>
                by which you may be personally identified, such as name, postal
                address, e-mail address, telephone number, utility billing
                information through a data service, social security number,
                government identification documents, date of birth, payment
                information and any other identifier by which you may be
                contacted online or offline ("
                <strong>personal information</strong>");
              </>,
              "that is required by third parties required to operate the Website and its functions such as Stripe, and utility API;",
              "that is about you but individually does not identify you, such as energy usage information; and/or",
              "about your internet connection, the equipment you use to access our Website and usage details.",
            ])}
          </Box>
          <Box>
            {Text("We collect this information:")}
            {List([
              "Directly from you when you provide it to us.",
              "Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.",
              "From third parties, for example, our business partners.",
            ])}
          </Box>
          <Box>
            {Text(
              "Information You Provide to Us.  The information we collect on or through our Website may include:"
            )}
            {List([
              "Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you report a problem with our Website.",
              "Records and copies of your correspondence (including email addresses), if you contact us.",
              "Your responses to surveys that we might ask you to complete for research purposes.",
              "Your electronic signature for completion of relevant forms",
            ])}
          </Box>
          <Box>
            {Text(
              <>
                You also may provide information to be published or displayed
                (hereinafter, <strong>"posted"</strong>) on public areas of the
                Website, or transmitted to other users of the Website or third
                parties (collectively, <strong>"User Contributions"</strong>).
                Your User Contributions are posted on and transmitted to others
                at your own risk. Although we limit access to certain pages/you
                may set certain privacy settings for such information by logging
                into your account profile, please be aware that no security
                measures are perfect or impenetrable. Additionally, we cannot
                control the actions of other users of the Website with whom you
                may choose to share your User Contributions. Therefore, we
                cannot and do not guarantee that your User Contributions will
                not be viewed by unauthorized persons.
              </>
            )}
          </Box>
          <Box>
            {Text(
              "Information We Collect Through Automatic Data Collection Technologies.  As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:"
            )}
            {List([
              "Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.",
              "Information about your computer and internet connection, including your IP address, operating system, and browser type.",
            ])}
          </Box>
          <Box>
            {Text(
              "The information we collect automatically is statistical data and may include personal information, or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:"
            )}
            {List([
              "Estimate our audience size and usage patterns.",
              "Store information about your preferences, allowing us to customize our Website according to your individual interests.",
              "Speed up your searches.",
              "Recognize you when you return to our Website.",
            ])}
          </Box>
          <Box>
            {Text(
              "The technologies we use for this automatic data collection may include:"
            )}
            {List([
              <>
                <strong>Cookies (or browser cookies).</strong> A cookie is a
                small file placed on the hard drive of your computer. You may
                refuse to accept browser cookies by activating the appropriate
                setting on your browser. However, if you select this setting you
                may be unable to access certain parts of our Website. Unless you
                have adjusted your browser setting so that it will refuse
                cookies, our system will issue cookies when you direct your
                browser to our Website.{" "}
              </>,
              <>
                <strong>Flash Cookies.</strong> Certain features of our Website
                may use local stored objects (or Flash cookies) to collect and
                store information about your preferences and navigation to,
                from, and on our Website. Flash cookies are not managed by the
                same browser settings as are used for browser cookies. For
                information about managing your privacy and security settings
                for Flash cookies, see{" "}
                {LinkText(
                  "Choices About How We Use and Disclose Your Information.",
                  path.PRIVACY
                )}
              </>,
              <>
                <strong>Web Beacons.</strong> Pages of the Website and our
                e-mails may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who
                have visited those pages or opened an email and for other
                related website statistics (for example, recording the
                popularity of certain website content and verifying system and
                server integrity).{" "}
              </>,
            ])}
          </Box>
          <Box>
            {Text(
              "We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us."
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "Third-Party Use of Cookies and Other Tracking Technologies.",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content."
            )}
          </Box>
          <Box>
            {Text(
              "We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly."
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "How We Use Your Information",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "We use information that we collect about you or that you provide to us, including any personal information:"
            )}
            {List([
              "To present our Website and its contents to you.",
              "To provide you with information, products, or services that you request from us.",
              "To fulfill any other purpose for which you provide it.",
              "To provide you with notices about your account/subscription, including expiration and renewal notices.",
              "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.",
              "To notify you about changes to our Website or any products or services we offer or provide though it.",
              "To allow you to participate in interactive features on our Website.",
              "In any other way we may describe when you provide the information.",
              "For any other purpose with your consent.",
            ])}
          </Box>
        </>
      );
    },
  },
  {
    title: "Disclosure of Your Information",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. "
            )}
            {Text(
              "We may disclose personal information that we collect or you provide as described in this privacy policy:"
            )}
            {List([
              "To our subsidiaries and affiliates.",
              "To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.",
              "To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of MySunBuddy, Inc.'s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by MySunBuddy about our Website users is among the assets transferred.",
              "To fulfill the purpose for which you provide it.",
              "For any other purpose disclosed by us when you provide the information.",
              "With your consent.",
            ])}
          </Box>
          <Box>
            {Text("We may also disclose your personal information:")}
            {List([
              "To comply with any court order, law, or legal process, including to respond to any government or regulatory request.",
              "To enforce or apply our terms of use, and other agreements, including for billing and collection purposes.",
              "If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of MySunBuddy, Inc., our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.",
            ])}
          </Box>
        </>
      );
    },
  },
  {
    title: "Choices About How We Use and Disclose Your Information",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:"
            )}
            {List([
              <>
                <strong>Tracking Technologies and Advertising.</strong> You can
                set your browser to refuse all or some browser cookies, or to
                alert you when cookies are being sent. To learn how you can
                manage your Flash cookie settings, visit the Flash player
                settings page on Adobe's
                {LinkText("website", "http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html")}.
                If you disable or refuse cookies,
                please note that some parts of this site may then be
                inaccessible or not function properly.
              </>,
              "We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (NAI) on the NAI's website.",
            ])}
          </Box>
        </>
      );
    },
  },
  {
    title: "Accessing and Correcting Your Information",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "You can review and change your personal information by logging into the Website and visiting your account profile page."
            )}
          </Box>
          <Box>
            {Text(
              <>
                You may also send us an email to{" "}
                {LinkText("info@mysunbuddy.com", "mailto:info@mysunbuddy.com", true)} with subject line
                “Correcting User Account” to request access to, correct or
                delete any personal information that you have provided to us. We
                cannot delete your personal information except by also deleting
                your user account. We may not accommodate a request to change
                information if we believe the change would violate any law or
                legal requirement or cause the information to be incorrect.
              </>
            )}
          </Box>
          <Box>
            {Text(
              "If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by our terms of use."
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "Your California Privacy Rights",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              <>
                California Civil Code Section § 1798.83 permits users of our
                Website that are California residents to request certain
                information regarding our disclosure of personal information to
                third parties for their direct marketing purposes. To make such
                a request, please send an email to{" "}
                {LinkText("info@mysunbuddy.com", "mailto:info@mysunbuddy.com", true)} or write to us at: 6
                Liberty Square, #2683 Boston, MA 02109.
              </>
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "Data Security",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure."
            )}
          </Box>
          <Box>
            {Text(
              "The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website."
            )}
          </Box>
          <Box>
            {Text(
              "Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website."
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "Changes to Our Privacy Policy",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you by email to the primary email address specified in your account. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes."
            )}
          </Box>
        </>
      );
    },
  },
  {
    title: "Contact Information",
    content: () => {
      return (
        <>
          <Box>
            {Text(
              "To ask questions or comment about this privacy policy and our privacy practices, contact us at:"
            )}
            {List([
              <>{LinkText("info@mysunbuddy.com", "mailto:info@mysunbuddy.com", true)}</>,
              "6 Liberty Square, #2683",
              "Boston, MA 02109",
            ], true)}
          </Box>
        </>
      );
    },
  },
];

const Text = (item) => (
  <>
    <Typography
      variant="subtitle2"
      className="text-subheading font-open-sans leading-8"
      align="left"
    >
      {item}
    </Typography>
  </>
);

const LinkText = (link, url, mailTo) => {
  let prop = {}
  if (mailTo) {
    prop['to'] = '#'
    prop['onClick'] = (e) => {
      window.location.href = url;
      e.preventDefault();
    }
  } else {
    prop['to'] = url
  }
  return (
    <Link className="no-underline" {...prop}>
      <Typography
        variant="subtitle2"
        className="text-secondary font-open-sans"
        align="left"
        component="span"
      >
        {" "}
        {link}{" "}
      </Typography>
    </Link>
  )
}

const List = (item, hideBullets = false) => (
  <>
    <ul className={hideBullets ? "list-none p-0 m-0" : ""}>
      {item.map((x) => (
        <li key={x}>
          <Typography
            variant="subtitle2"
            className="text-subheading font-open-sans leading-8"
            align="left"
          >
            {x}
          </Typography>
        </li>
      ))}
    </ul>
  </>
);

const TextSection = () => {
  return (
    <>
      <Container>
        {data.map((x) => (
          <Box key={x.title} className="py-8">
            <Typography
              variant="h6"
              className="text-subheading font-jost pb-4"
              align="left"
            >
              {x.title}
            </Typography>
            {x.content()}
          </Box>
        ))}
      </Container>
    </>
  );
};

export default TextSection;