import React from "react";
import {SendRounded} from "@material-ui/icons";
import {
  TextField as Input,
  InputAdornment,
  Button,
  Box
} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";

const CustomTextField = styled(Input)({
  borderTopLeftRadius: "16px",
  borderBottomLeftRadius: "16px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#45364B",
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
    },
    "&:hover fieldset": {
      borderColor: "#45364B",
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#45364B",
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px",
    },
  },
  "& .MuiInputBase-input": {
    color: "#ffffff",
    "&::placeholder": {
      color: "#e1e1e1",
      opacity: 0.5,
    },
  },
});

const CustomButton = styled(Button)({
  borderRadius: "0px",
  backgroundColor: "#f0a81a",
  color: "#000000",
  borderTopRightRadius: "16px",
  borderBottomRightRadius: "16px",
  "&:hover": {
    backgroundColor: "#f0a81a",
  },
});

const LandingPageInputField = (props) => {
  return (
    <>
      <Box className="flex">
        <CustomTextField
          variant="outlined"
          type={props.type}
          placeholder={props.placeholder}
          style={{
            backgroundColor: "#45364B",
            width: "90%",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{color: "#e1e1e1"}}>
                {props.icon}
              </InputAdornment>
            ),
          }}
          {...props}
        />
        <CustomButton size="small" style={{width: "10%"}} onClick={props.onBtnClick}>
          <SendRounded/>
        </CustomButton>
      </Box>
    </>
  );
};

export default LandingPageInputField;
