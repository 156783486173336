import React from "react";
import {ArrowForward} from "@material-ui/icons";
import {Grid, Box, Typography, useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

import PrimaryButton from "../../FormComponents/PrimaryButton";

import LeftImage from "../../../assets/HomePage/Rectangle5.png";
import RightImage from "../../../assets/HomePage/Rectangle6.png";

import {SIGN_UP} from "../../../constants/routes";
import {useHistory} from "react-router-dom";
import * as path from "../../../constants/routes";


const PictureSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory()

  const goToSignUp = (userType) => {
    history.push({
      pathname: path.SIGN_UP,
      search: `?customer_type=${userType}`,  // query string
      state: {  // location state
        update: true,
      },
    });
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        className="my-16 overflow-hidden"
      >
        <Grid item md={6} className="pr-3">
          <Box component="span">
            <Box className="relative" data-aos="slide-right" component="div">
              <img
                src={LeftImage}
                className="object-cover"
                alt="logo"
                width="100%"
                style={{
                  borderTopRightRadius: "32px",
                  borderBottomRightRadius: "32px",
                  height: "85vh",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.7)",
                  opacity: "100%",
                  borderTopRightRadius: "32px",
                  borderBottomRightRadius: "32px",
                }}
                className="flex justify-center items-center"
                component="div"
              >
                <Box width="50%" component="div">
                  <Typography
                    variant="h4"
                    className="font-jost text-white pt-1 font-bold mb-8 leading-normal"
                    align={isMobile ? "center" : "left"}
                    component="div"
                  >
                    I’m a Clean Energy Producer
                  </Typography>
                  {!isMobile ? (
                    <Typography
                      variant="body1"
                      className="font-open-sans text-white mb-8"
                      align="left"
                      component="div"
                    >
                      Do you have solar on your rooftop? Are you considering a
                      solar installation? Please sign-up for the platform as a
                      Clean Energy Producer. Producers own renewable energy
                      systems and will be paired with households in need of
                      electricity bill relief within your community. These
                      households will receive donations of utility bill credits
                      generated by your solar system.
                    </Typography>
                  ) : null}
                  <Box align="left" component="span">
                    <PrimaryButton
                      name={
                        <Box className="flex items-start" component="span">
                          Register now
                          <ArrowForward
                            style={{
                              height: "15px",
                              paddingTop: "6px",
                            }}
                          />
                        </Box>
                      }
                      width="12rem"
                      textVariant="body1"
                      onClick={() => goToSignUp('seller')}
                    ></PrimaryButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} className="pl-3">
          <Box component="span">
            <Box className="relative" data-aos="slide-left" component="div">
              <img
                src={RightImage}
                className="object-cover"
                alt="logo"
                width="100%"
                style={{
                  borderTopLeftRadius: "32px",
                  borderBottomLeftRadius: "32px",
                  height: "85vh",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background: "rgba(0, 0, 0, 0.7)",
                  opacity: "100%",
                  borderTopLeftRadius: "32px",
                  borderBottomLeftRadius: "32px",
                }}
                className="flex justify-center items-center"
                component="div"
              >
                <Box width="52%" component="div">
                  <Typography
                    variant="h4"
                    className="font-jost text-white pt-1 font-bold mb-8 leading-normal"
                    align={isMobile ? "center" : "left"}
                    component="div"
                  >
                    I’m a Clean Energy Consumer
                  </Typography>
                  {!isMobile ? (
                    <Typography
                      variant="body1"
                      className="font-open-sans text-white mb-8"
                      align="left"
                      component="div"
                    >
                      Would you like to benefit directly from the clean energy transition by receiving a discount on
                      your electricity bills? Please sign-up as a Clean Energy Consumer. As soon as bill credits are
                      available within our network, you will receive discounts on your electricity bill at no-cost to
                      you. In order to qualify for this service, you must be referred by one of our community partners
                      or demonstrate that you are income-eligible.
                    </Typography>
                  ) : null}
                  <Box align="left" component="span">
                    <PrimaryButton
                      name={
                        <Box className="flex items-start" component="span">
                          Register now
                          <ArrowForward
                            style={{
                              height: "15px",
                              paddingTop: "6px",
                            }}
                          />
                        </Box>
                      }
                      width="12rem"
                      textVariant="body1"
                      onClick={() => goToSignUp('buyer')}
                    ></PrimaryButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default PictureSection;
