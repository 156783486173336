import React from "react";
import axios from "axios";
import {CHANGE_PASSWORD} from "../../../store/apiUrls/apiUrls";
import {Link} from "react-router-dom";
import * as path from "../../../constants/routes";
import {Formik, Form} from "formik";
import {ChangePasswordSchema} from "./validation";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "../../FormComponents/TextField";
import PrimaryButton from "../../FormComponents/PrimaryButton";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const ChangePasswordForm = (props) => {

  const changePassword = (values) => {
    axios({
      method: "patch",
      url: CHANGE_PASSWORD,
      headers: {Authorization: `Token ${localStorage.getItem("token")}`},
      data: {
        id: props.userId,
        old_password: values.old_password,
        password: values.password,
        password_confirm: values.password_confirm,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          props.handleClose();
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
  };

  return (
    <Formik
      initialValues={{
        old_password: "",
        password: "",
        password_confirm: "",
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={changePassword}
    >
      {({
          values: {password, password_confirm, old_password},
          touched,
          errors,
          setFieldValue,
        }) => (
        <Form>
          <Box>
            <InputLabel htmlFor="old_password" className="text-left ml-4">
              Old password
            </InputLabel>
            <TextField
              type={"password"}
              id="old_password"
              name="old_password"
              helperText={touched.old_password ? errors.old_password : ""}
              error={touched.old_password && Boolean(errors.old_password)}
              value={old_password}
              onChange={(e) => {
                setFieldValue("old_password", e.target.value);
              }}
              fullWidth
              variant="outlined"
              size="small"
              className="mt-2"
              autoComplete={"off"}
              icon={<LockOutlinedIcon/>}
            />
          </Box>
          <Box>
            <InputLabel htmlFor="username" className="text-left ml-4">
              Password
            </InputLabel>
            <TextField
              type={"password"}
              id="password"
              name="password"
              helperText={touched.password ? errors.password : ""}
              error={touched.password && Boolean(errors.password)}
              value={password}
              onChange={(e) => {
                setFieldValue("password", e.target.value);
              }}
              fullWidth
              variant="outlined"
              size="small"
              className="mt-2"
              autoComplete={"off"}
              icon={<LockOutlinedIcon/>}
            />
          </Box>
          <Box>
            <InputLabel htmlFor="username" className="text-left ml-4 mt-3">
              Repeat password
            </InputLabel>
            <TextField
              type={"password"}
              id="password_confirm"
              name="password_confirm"
              helperText={
                touched.password_confirm ? errors.password_confirm : ""
              }
              error={
                touched.password_confirm && Boolean(errors.password_confirm)
              }
              value={password_confirm}
              onChange={(e) => {
                setFieldValue("password_confirm", e.target.value);
              }}
              fullWidth
              variant="outlined"
              size="small"
              className="mt-2"
              autoComplete={"off"}
              icon={<LockOutlinedIcon/>}
            />
          </Box>

          <Box className="mt-3 text-right">
            <Link
              to={path.FORGOT_PASSWORD}
              className="no-underline text-subheading font-open-sans font-semibold"
            >
              Forgot password?
            </Link>
          </Box>

          <PrimaryButton name="Reset" large type="submit" className="mt-5"></PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
