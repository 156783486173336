import React from "react";
import {Typography, Checkbox as CheckBox} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";

const StyledCheckBox = styled(CheckBox)({
  color: "#f0a81a",
  marginRight: "5px",
  "&.Mui-checked": {
    color: "#f0a81a",
  },
});

const Checkbox = (props) => {
  return (
    <>
      <Typography
        variant="subtitle2"
        align="left"
        className="text-subheading font-open-sans"
      >
        <StyledCheckBox size={props.small ? "small" : ""}/>
        {props.label}
      </Typography>
    </>
  );
};

export default Checkbox;
