import React, { useState, useEffect } from "react";
import * as path from "../../constants/routes";
import axios from "axios";
import * as API_URL from "../../store/apiUrls/apiUrls";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ResetPasswordForm from "../../components/Forms/ResetPasswordForm/index";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  },
  background: {
    height: "120%",
    width: "432px",
    backgroundColor: "#EEF1F7",
    transform: "rotate(15deg)",
    position: "absolute",
    marginLeft: "135px",
  },
  card: {
    width: "392px",
    zIndex: 10,
    padding: "65px 40px",
    borderRadius: "32px",
    border: "none",
    [theme.breakpoints.down(500)]: {
      width: "100%",
      height: "100%",
      borderRadius: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& form": {
        width: "100%",
      },
    },
  },
  button: {
    marginTop: "10px",
    backgroundColor: "#4776E6",
    minWidth: "165px",
    marginBottom: "10px",
  },
  title: {
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "47px",
    marginBottom: "20px",
    color: "#1F8B24",
  },
  caption: {
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "20px",
    marginBottom: "20px",
    color: "#BDBDBD",
  },
  successSection: {
    minHeight: "450px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: "#1F8B24",
    width: "60px",
    height: "60px",
  },
}));

const ResetPassword = (props) => {
  const [success, setSucces] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!props.location.state) {
      props.history.push(path.FORGOT_PASSWORD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPassword = (values) => {
    const userId = props.location.state.userId;
    axios({
      method: "post",
      url: API_URL.CHANGE_FORGOT_PASSWORD,
      data: {
        user_id: userId,
        password_1: values.password_1,
        password_2: values.password_2,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setSucces(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
  };

  const done = () => {
    props.history.push(path.SIGN_IN);
  };

  return (
    <Box className={classes.root}>
      <div className={classes.background}></div>
      <Card variant="outlined" className={classes.card}>
        {success ? (
          <div className={classes.successSection}>
            <CheckCircleOutlineIcon className={classes.icon} />
            <Typography variant="h3" className={classes.title}>
              Success
            </Typography>
            <Typography variant="h5" className={classes.caption}>
              Your password has been successfully changed
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.button}
                onClick={done}
              >
                done
              </Button>
            </Box>
          </div>
        ) : (
          <ResetPasswordForm resetPassword={resetPassword} />
        )}
      </Card>
    </Box>
  );
};

export default ResetPassword;
