import {
  Grid,
  Paper,
  Box,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import {Formik, Form} from "formik";
import {EmailOutlined} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import PrimaryButton from "../../components/FormComponents/PrimaryButton";
import TextField from "../../components/FormComponents/TextField";
import {ForgotPasswordSchema} from "./validation";
import * as path from "../../constants/routes";

const ForgotPassword = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="min-h-screen bg-ternary"
      >
        <Grid
          item
          className={isMobile ? "my-8 min-w-full" : "my-8 min-w-[30%]"}
        >
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={props.sendInstruction}
          >
            {({values: {email}, touched, errors, setFieldValue}) => (
              <Paper
                elevation={3}
                className={
                  isMobile ? "py-20 px-4 rounded-2xl" : "py-20 px-16 rounded-2xl"
                }
              >

                <Form>
                  <Grid container justifyContent="flex-start" direction="column">
                    <Typography
                      variant="h4"
                      className="font-jost text-primary text-left font-bold"
                    >
                      SEP
                    </Typography>
                    <Typography
                      variant="h3"
                      className="font-jost text-heading font-bold text-left pb-1 mt-6"
                    >
                      Forgot Password?
                    </Typography>
                    <TextField
                      helperText={touched.email ? errors.email : ""}
                      error={touched.email && Boolean(errors.email)}
                      value={email}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      placeholder="Example@email.com"
                      type="email"
                      className="mt-6"
                      icon={<EmailOutlined/>}
                    />
                    <PrimaryButton
                      name="Send"
                      className="mt-6"
                      type="submit"
                    ></PrimaryButton>
                    <Box className="mt-6">
                      <Typography
                        variant="h6"
                        className="font-open-sans text-gray-500"
                        component="span"
                      >
                        Dont have an account?
                      </Typography>{" "}
                      <Typography variant="h6" component="span">
                        <Link
                          to={path.SIGN_UP}
                          className="no-underline text-heading font-open-sans"
                        >
                          Register
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Form>
              </Paper>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;