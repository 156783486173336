import React from 'react';

import Select, {
  components,
  ControlProps,
  Props,
  StylesConfig,
} from 'react-select';
import {styled} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";


const StyledDiv = styled(Box)({
  "& .basic-single, .select__control": {
    backgroundColor: "#F6F6F6",
    cursor: "text"
  },
  "& .basic-single": {
    borderRadius: "12px"
  },
  "& .select__control": {
    backgroundColor: "#F6F6F6 !important",
    borderColor: "#F6F6F6 !important",
    boxShadow: "inherit !important",
    borderRadius: "12px"
  },
  "& .select__menu": {
    backgroundColor: "#F6F6F6 !important",
    borderColor: "#F6F6F6",
    boxShadow: "inherit !important",
    cursor: "pointer !important",
  },
  "& .select__value-container": {
    caretColor: "transparent",
    padding: "17.5px 5px"
  },
  "& .size-small > .select__control > .select__value-container": {
    padding: "9px 5px"
  },
  "& *": {
    fontFamily: "Jost"
  },
  "& .select__option": {
    cursor: "pointer",
  },
  "& .select__indicator-separator": {
    display: "none",
  },
  "& .MuiSvgIcon-root": {
    fill: "#6D6D6D",
  },
  "& .error > .select__control": {
    borderColor: "#f44336 !important",
  }
});

const Control = ({children, ...props}) => {
  return (
    <components.Control {...props}>
      <span className="ml-4 mt-1">
        {props.selectProps.icon}
      </span>
      {children}
    </components.Control>
  );
};

const ReactSelect = (props) => {

  let className = props?.error?.length ? "basic-single error" : "basic-single"
  className += ' ' + props.className

  const defaultValue = props.options.find((item) => item.value === props.value)

  return (
    <StyledDiv>
      <Select
        defaultValue={defaultValue}
        components={{Control}}
        className={className}
        classNamePrefix="select"
        isSearchable={true}
        name="color"
        options={props.options}
        placeholder={props.placeholder}
        icon={props.icon}
        onChange={props.onChange}
        styles={{
          height: 20,
          minHeight: 20
        }}
        size="small"
      />
      {props?.error?.length && (
        <p
          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
          id="seller_code-helper-text"
          style={{
            fontSize: "0.8rem",
            marginLeft: "1rem"
          }}
        >
          {props.error}
        </p>
      )}
    </StyledDiv>
  )
}
export default ReactSelect
