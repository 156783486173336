import { combineReducers } from "redux";
import { reducerLoader } from "./reducerLoader";
import { reducerProfile } from "./reducerProfile";
import { reducerUtilityZones } from "./reducerUtilityZones";
import { reducerDeals } from "./reducerDeals";
import { reducerSellerCodes } from "./reducerSellerCodes";

const rootReducer = combineReducers({
  loader: reducerLoader,
  profile: reducerProfile,
  utility_zones: reducerUtilityZones,
  deals: reducerDeals,
  seller_codes: reducerSellerCodes,
});

export default rootReducer;
