import React from "react";
import ReactDOM from "react-dom";

import {BrowserRouter} from "react-router-dom";

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import "./assets/css/global.css"

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import {Provider} from "react-redux";
import {store} from "./store/store";

const stripePromise = loadStripe("pk_test_51JmdaRF1IWejJ2UFIzk7eZeyoBTMknhGbN7BTNIL9Uh9huGFxLx5TgfutjZnEsjB0EQLFWKwrFw9vherg30CipxT00bB6q22il");

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <App/>
      </Elements>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
