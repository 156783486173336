import React from "react";
import {Box, Grid, Paper, Typography, useMediaQuery} from "@material-ui/core";
import {registerUser} from "../../store/actions/profile";
import {useDispatch, useSelector} from "react-redux";
import {get_utility_zones} from "../../store/actions/utilityZones";
import {get_seller_codes} from "../../store/actions/sellerCodes";

import {useTheme} from "@material-ui/core/styles";

import * as path from "../../constants/routes";

import {Formik, Form} from "formik";
import {SignupSchema} from "../../components/Forms/SignUpForm/validation";
import RadioOptions from "../../components/FormComponents/RadioOptions";
import TextField from "../../components/FormComponents/TextField";
import PrimaryButton from "../../components/FormComponents/PrimaryButton";
import {Link} from "react-router-dom";

import {
  DescriptionOutlined,
  EmailOutlined,
  LocalPhoneOutlined,
  LockOutlined,
  PersonOutline,
  RoomOutlined,
  WorkOutline,
} from "@material-ui/icons";
import Header from "../../components/HomePage/Header";
import ReactSelect from "../../components/FormComponents/ReactSelect";

const radioOptions = [
  {
    label: "Clean Energy Consumer (I would like to benefit from solarenergy)",
    value: "buyer",
  },
  {
    label: "Clean Energy Producer (I have a solar system)",
    value: "seller",
  },
];

const SignUp = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const utility_zones = useSelector(
    (state) => state.utility_zones.utility_zones
  );
  const seller_codes = useSelector(
    (state) => state.seller_codes.data
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!utility_zones) {
      dispatch(get_utility_zones());
    }

    if (!seller_codes.length) {
      dispatch(get_seller_codes());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let arrUtilityZones = utility_zones ? Object.keys(utility_zones) : [];
  let utilityZones = utility_zones ? arrUtilityZones.map((key) => {
    return {
      value: key,
      label: utility_zones[key],
    };
  }) : [];


  let sellerCodes = seller_codes ? seller_codes.map((code) => {
    return {
      value: code,
      label: code,
    };
  }) : [];

  const handleSignUp = async (values, {setFieldError}) => {
    const moveTo = () => {
      props.history.push(path.PROFILE);
    };
    dispatch(registerUser(values, setFieldError, moveTo));
  };

  const urlParams = new URLSearchParams(props.location.search)
  let customer_type = urlParams.get('customer_type')
  let email = urlParams.get('email')

  return (
    <>
      <Header></Header>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="min-h-screen bg-ternary"
      >
        <Grid
          item
          style={{
            maxWidth: isMobile ? "100%" : "70%",
            minWidth: "40%",
          }}
          className="my-8"
        >
          <Formik
            initialValues={{
              username: "",
              phone_number: "",
              email: email || "",
              address: "",
              password: "",
              utility_zone: "",
              password_confirm: "",
              customer_type: ["buyer", "seller"].includes(customer_type) ? customer_type : "seller",
              seller_code: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={handleSignUp}
          >
            {({
                values: {
                  username,
                  phone_number,
                  email,
                  address,
                  password,
                  utility_zone,
                  password_confirm,
                  customer_type,
                  seller_code,
                },
                touched,
                errors,
                setFieldValue,
                formik
              }) => (
              <Paper
                elevation={3}
                className={
                  isMobile ? "py-20 px-4 rounded-2xl" : "py-20 px-16 rounded-2xl"
                }
              >
                <Form>
                  <Grid container justifyContent="flex-start" direction="column">
                    <Typography
                      variant="h4"
                      className="font-jost text-primary text-left font-bold"
                    >
                      SEP
                    </Typography>
                    <Typography
                      variant="h3"
                      className="font-jost text-heading font-bold text-left pb-1 mt-6"
                    >
                      Create an account
                    </Typography>
                    <RadioOptions
                      value={customer_type}
                      onChange={(e) => {
                        setFieldValue("customer_type", e.target.value);
                        if (e.target.value === "seller") setFieldValue("seller_code", "");
                      }}
                      options={radioOptions}
                      className="mt-6"
                    ></RadioOptions>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      className="mt-6"
                    >
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="username"
                          name="username"
                          placeholder="Full name"
                          type="text"
                          helperText={touched.username ? errors.username : ""}
                          error={touched.username && Boolean(errors.username)}
                          value={username}
                          onChange={(e) => {
                            setFieldValue("username", e.target.value);
                          }}
                          fullWidth
                          icon={<PersonOutline/>}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="phone_number"
                          name="phone_number"
                          placeholder="Phone"
                          type="text"
                          fullWidth
                          helperText={touched.phone_number ? errors.phone_number : ""}
                          error={touched.phone_number && Boolean(errors.phone_number)}
                          value={phone_number}
                          onChange={(e) => {
                            setFieldValue("phone_number", e.target.value);
                          }}
                          icon={<LocalPhoneOutlined/>}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="email"
                          fullWidth
                          helperText={touched.email ? errors.email : ""}
                          error={touched.email && Boolean(errors.email)}
                          value={email}
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                          }}
                          icon={<EmailOutlined/>}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="address"
                          name="address"
                          placeholder="Address"
                          type="text"
                          fullWidth
                          helperText={touched.address ? errors.address : ""}
                          error={touched.address && Boolean(errors.address)}
                          value={address}
                          onChange={(e) => {
                            setFieldValue("address", e.target.value);
                          }}
                          icon={<RoomOutlined/>}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="password"
                          name="password"
                          placeholder="Password"
                          type="password"
                          fullWidth
                          helperText={touched.password ? errors.password : ""}
                          error={touched.password && Boolean(errors.password)}
                          value={password}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value);
                          }}
                          icon={<LockOutlined/>}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          id="password_confirm"
                          name="password_confirm"
                          placeholder="Confirm Password"
                          type="password"
                          fullWidth
                          helperText={
                            touched.password_confirm ? errors.password_confirm : ""
                          }
                          error={
                            touched.password_confirm &&
                            Boolean(errors.password_confirm)
                          }
                          value={password_confirm}
                          onChange={(e) => {
                            setFieldValue("password_confirm", e.target.value);
                          }}
                          icon={<LockOutlined/>}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <ReactSelect
                          icon={<DescriptionOutlined/>}
                          placeholder="Utility zone"
                          options={utilityZones}
                          onChange={(e) => {
                            setFieldValue("utility_zone", e.value);
                          }}
                          error={touched.utility_zone && errors.utility_zone}
                          formik={formik}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {customer_type === "seller" && (
                          <TextField
                            id="seller_code"
                            name="seller_code"
                            placeholder="Agreement code"
                            type="text"
                            fullWidth
                            helperText={touched.seller_code ? errors.seller_code : ""}
                            error={touched.seller_code && Boolean(errors.seller_code)}
                            value={seller_code}
                            onChange={(e) => {
                              setFieldValue("seller_code", e.target.value);
                            }}
                            icon={<WorkOutline/>}
                          />
                        )}

                        {customer_type === "buyer" && (
                          <ReactSelect
                            icon={<WorkOutline/>}
                            placeholder="Agreement code"
                            options={sellerCodes}
                            onChange={(e) => {
                              setFieldValue("seller_code", e.value);
                            }}
                            error={touched.seller_code && errors.seller_code}
                            formik={formik}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Box justifyContent="center" alignItems="center" className="mt-6">
                      <PrimaryButton name="Register my account" large type="sumit"></PrimaryButton>
                    </Box>
                    <Box className="mt-6">
                      <Typography
                        variant="h6"
                        className="font-open-sans text-gray-500"
                        component="span"
                      >
                        Already have an account?
                      </Typography>{" "}
                      <Typography
                        variant="h6"
                        className="font-open-sans text-heading"
                        component="span"
                      >
                        <Link
                          to={path.SIGN_IN}
                          className="no-underline text-heading font-open-sans"
                        >
                          Login
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Form>
              </Paper>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUp;
