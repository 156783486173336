import { Grid } from "@material-ui/core";
import Header from "../../components/HomePage/Header";
import Banner from "../../components/PrivacyPolicy/Banner";
import TextSection from "../../components/PrivacyPolicy/TextSection";
import Footer from "../../components/HomePage/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header></Header>
      <Grid container>
        <Grid item md={12} sm={12}>
            <Banner text="Privacy Policy"></Banner>
        </Grid>
        <Grid item md={12} sm={12}>
           <TextSection></TextSection>
        </Grid>
        <Grid item md={12} sm={12}>
          <Footer></Footer>
        </Grid>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;