import React from "react";
import {
  Grid, useTheme,
  Typography,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import {Fragment} from "react";
import featureSvg from "../../../assets/HomePage/feature-icon.svg";
import Feature1 from "../../../assets/HomePage/Feature1.svg";
import Feature2 from "../../../assets/HomePage/Feature2.svg";
import Feature3 from "../../../assets/HomePage/Feature3.svg";
import Feature4 from "../../../assets/HomePage/Feature4.svg";

const Feature = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const data = [
    {
      title: "Build more clean energy:",
      description:
        "Size your system to fulfill both local and community energy needs",
      image: Feature1,
    },
    {
      title: "Lower your energy bills:",
      description:
        "Once a match is made, credits will lower bill each month.",
      image: Feature2
    },
    {
      title: "Improve your project economics",
      description:
        "SEP can allow for installers to reduce unit costs.",
      image: Feature3
    },
    {
      title: "Contribute to community campaigns:",
      description:
        "Community partners can participate in zero cost credits to their energy bill.",
      image: Feature4
    },
  ];
  return data.map((item) => {
    return (
      <Fragment key={item.title}>
        <Grid item md={3} xs={12} className="pt-16">
          <Box>
            <Box align={isMobile ? "center" : "left"}>
              <img
                src={item.image}
                alt="logo"
                className={isMobile ? "" : "h-24"}
              />
            </Box>
            <Typography
              variant="subtitle1"
              align={isMobile ? "center" : "left"}
              className="font-jost text-heading pt-5"
              style={{fontWeight: 600}}
            >
              {item.title}
            </Typography>
            <Typography
              variant="subtitle2"
              align={isMobile ? "center" : "left"}
              className="text-subheading font-open-sans mt-1"
            >
              {item.description}
            </Typography>
          </Box>
        </Grid>
      </Fragment>
    );
  });
}
export default Feature