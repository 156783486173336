import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const PageNotFound = () => (
  <Grid container direction="row" justify="center" alignItems="center">
    <Box p={4}>
      <h4> Page not found :( </h4>
    </Box>
  </Grid>
);

export default PageNotFound;
