import React from "react";
import {Facebook, Instagram, Twitter} from "@material-ui/icons";
import {Grid, Typography, Container, Box} from "@material-ui/core";
import {Link} from "react-router-dom";
import * as path from "../../../constants/routes"

const Footer = () => {
  return (
    <>
      <Container>
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          alignItems="flex-start"
          className="text-primary my-20"
        >
          <Grid item md={6} xs={12}>
            <Box spacing={3}>
              <Typography
                variant="h4"
                align="left"
                className="text-secondary font-jost font-bold mb-4"
              >
                SEP
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                className="font-open-sans text-subheading mb-4 w-2/5"
              >
                Donate utility bill credits within your community.
              </Typography>
              <Box align="left">
                <Facebook className="text-grey hover:text-primary h-5 mr-2"/>
                <Instagram className="text-grey hover:text-primary h-5 mr-2"/>
                <Twitter className="text-grey hover:text-primary h-5 mr-2"/>
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} xs={12}>
            <Box spacing={3}>
              <Typography
                variant="subtitle2"
                align="left"
                className="font-jost text-heading font-semibold mb-4"
              >
                HELPFUL LINKS
              </Typography>

              <Link className="no-underline" to={path.PRIVACY}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  className="font-open-sans text-subheading mb-4"
                >
                  Privacy & Policy
                </Typography>
              </Link>

              <Link className="no-underline" to={path.TOS}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  className="font-open-sans text-subheading mb-4"
                >
                  Terms & Conditions
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography
              variant="subtitle2"
              className="font-open-sans text-subheading"
            >
              SEP 2022. All rights reserved
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Footer;
