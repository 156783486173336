import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {useTheme} from "@material-ui/core";

import Divider from "../LayoutComponents/Divider";

import LeftImage from "../../../assets/HomePage/Rectangle7.png";
import RightImage from "../../../assets/HomePage/Rectangle8.png";

const InfoSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box className={isMobile ? "relative pt-20" : "relative py-32"} id="about-section">
        <Grid
          container
          justifyContent="space-between"
          style={{paddingBottom: isMobile ? null : "300px"}}
        >
          <Grid
            item
            md={11}
            xs={12}
            className="bg-primary"
            data-aos="slide-right"
            style={{borderRadius: "16px"}}
          >
            <Box
              spacing={5}
              sx={{
                paddingX: isMobile ? "1rem" : "8%",
                paddingTop: "4rem",
                paddingBottom: isMobile ? "4rem" : "12rem",
              }}
            >
              <Divider className="mb-8"></Divider>
              <Typography
                variant="h4"
                className="font-jost text-white pt-1 font-bold leading-normal w-8/12 mb-8"
                align="left"
              >
                Increase energy affordability
              </Typography>
              <Typography
                variant="body1"
                className="font-open-sans text-white leading-loose"
                style={{width: isMobile ? "100%" : "50%"}}
                align="left"
              >
                Resonant Energy, a Boston-based solar provider, has partnered with MySunBuddy, a software company, to
                build this platform for community campaigns in Massachusetts. This pilot has been made possible with
                support from the U.S. Department of Energy and the Massachusetts Clean Energy Center.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          data-aos="fade-up"
          style={{
            position: isMobile ? null : "absolute",
            bottom: 0,
            left: "7rem",
            borderRadius: "30px",
            height: 'auto',
            margin: isMobile ? "1rem" : null,
            paddingTop: isMobile ? "2rem" : ""
          }}
          elevation={5}
        >
          <img
            src={LeftImage}
            className="object-cover"
            alt="logo"
            width="100%"
          />
        </Box>
        <Box
          data-aos="fade-out"
          style={{
            position: isMobile ? null : "absolute",
            bottom: "15%",
            right: "15%",
            borderRadius: "30px",
            margin: isMobile ? "1rem" : null,
          }}
          elevation={5}
        >
          <img
            src={RightImage}
            className="object-cover"
            alt="logo"
            width="100%"
          />
        </Box>
      </Box>
    </>
  );
};
export default InfoSection;
