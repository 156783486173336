import React, { useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { SELLER_REFRESH_STRIPE_URL } from "../../store/apiUrls/apiUrls";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  },
  background: {
    height: "120%",
    width: "432px",
    backgroundColor: "#EEF1F7",
    transform: "rotate(15deg)",
    position: "absolute",
    marginLeft: "135px",
  },
  text: {
    zIndex: 10,
  },
});

const StripeRefreshUrl = (props) => {
  const classes = useStyles();

  useEffect(() => {
    axios({
      method: "post",
      url: SELLER_REFRESH_STRIPE_URL,
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        window.open(response.data, "_self");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.status === 403) {
          window.location.href = "/login";
        }
      });
  });

  console.log("component");
  return (
    <Box className={classes.root}>
      <div className={classes.background}></div>
      <Typography className={classes.text} variant="h2">
        Something was wrong. Loading...
      </Typography>
    </Box>
  );
};

export default StripeRefreshUrl;
