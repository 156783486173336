import React, {useRef} from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import {Formik, Form} from "formik";
import {useTheme} from "@material-ui/core/styles";
import PrimaryButton from "../../components/FormComponents/PrimaryButton";
import TextField from "../../components/FormComponents/TextField";
import {validationCodeSchema} from "./validationCode";
import {Link} from "react-router-dom";


const SendInstruction = (props) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const itemRef2 = useRef(null);
  const itemRef3 = useRef(null);
  const itemRef4 = useRef(null);

  const textError = (errors) => {
    let text = "";
    for (var key in errors) {
      text = errors[key];
    }
    return text;
  };

  const {disabled, resendEmail} = props;

  let paperClass = isMobile ? "py-20 px-4 rounded-2xl" : "py-20 px-16 rounded-2xl"
  paperClass += " w-[30rem] m-auto";

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="min-h-screen bg-ternary"
      >
        <Grid
          item
          className={isMobile ? "my-8 min-w-full" : "my-8 min-w-[30%]"}
        >
          <Formik
            initialValues={{
              item1: "",
              item2: "",
              item3: "",
              item4: "",
            }}
            validationSchema={validationCodeSchema}
            onSubmit={props.checkCode}
          >
            {({
                values: {item1, item2, item3, item4},
                touched,
                errors,
                setFieldValue,
              }) => (
              <Paper
                elevation={3}
                className={paperClass}
              >

                <Form>
                  <Grid container justifyContent="flex-start" direction="column">
                    <Typography
                      variant="h4"
                      className="font-jost text-primary text-left font-bold"
                    >
                      You are almost set up
                    </Typography>
                    <Typography
                      variant="h5"
                      className="font-jost text-heading font-bold text-left pb-1 mt-6"
                    >
                      We`ve sent a letter with code to your email address
                    </Typography>
                    <Box component="div" className="flex w-2/3 m-auto my-3 gap-4">
                      <TextField
                        error={
                          Object.keys(errors).length && Object.keys(touched).length
                            ? true
                            : false
                        }
                        autoFocus
                        id="item1"
                        name="item1"
                        value={item1}
                        onChange={(e) => {
                          if (e.target.value.length >= 1) {
                            itemRef2.current.focus();
                            setFieldValue("item1", e.target.value[0]);
                          } else {
                            setFieldValue("item1", "");
                          }
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                      />
                      <TextField
                        error={
                          Object.keys(errors).length && Object.keys(touched).length
                            ? true
                            : false
                        }
                        inputRef={itemRef2}
                        id="item2"
                        name="item2"
                        value={item2}
                        onChange={(e) => {
                          if (e.target.value.length >= 1) {
                            itemRef3.current.focus();
                            setFieldValue("item2", e.target.value[0]);
                          } else {
                            setFieldValue("item2", "");
                          }
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                      />
                      <TextField
                        error={
                          Object.keys(errors).length && Object.keys(touched).length
                            ? true
                            : false
                        }
                        inputRef={itemRef3}
                        id="item3"
                        name="item3"
                        value={item3}
                        onChange={(e) => {
                          if (e.target.value.length >= 1) {
                            itemRef4.current.focus();
                            setFieldValue("item3", e.target.value[0]);
                          } else {
                            setFieldValue("item3", "");
                          }
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                      />
                      <TextField
                        error={
                          Object.keys(errors).length && Object.keys(touched).length
                            ? true
                            : false
                        }
                        inputRef={itemRef4}
                        id="item4"
                        name="item4"
                        value={item4}
                        onChange={(e) => {
                          if (e.target.value.length >= 1) {
                            setFieldValue("item4", e.target.value[0]);
                          } else {
                            setFieldValue("item4", "");
                          }
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                      />
                    </Box>

                    {Object.keys(errors).length && Object.keys(touched).length ? (
                      <Typography>
                        {textError(errors)}
                      </Typography>
                    ) : null}

                    <PrimaryButton
                      name="Done"
                      className="mt-6"
                      type="submit"
                    ></PrimaryButton>
                    <Box className="mt-6">
                      <Typography
                        variant="h6"
                        className="font-open-sans text-gray-500"
                        component="span"
                      >
                        Did not receive the code?{" "}
                      </Typography>{" "}
                      <Typography variant="h6" component="span">
                        <Link
                          className="no-underline text-heading font-open-sans"
                          onClick={resendEmail}
                          disabled={disabled}
                        >
                          Resend code
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Form>
              </Paper>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default SendInstruction;