import React from "react";
import {useHistory} from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  useMediaQuery
} from "@material-ui/core";

import {useTheme} from "@material-ui/core/styles";

import {EmailOutlined} from "@material-ui/icons";

import RadioOptions from "../../FormComponents/RadioOptions";
import LandingPageInputField from "./InputField";

import Illustration from "../../../assets/HomePage/Illustration.png";

import * as path from "../../../constants/routes"

const Welcome = (props) => {

  const history = useHistory()

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [userType, setUserType] = React.useState("seller");
  const [emailAddress, setEmailAddress] = React.useState("");

  const radioOptions = [
    {
      label: "I have a solar energy system",
      value: "seller",
    },
    {
      label: "I don’t have a solar energy system",
      value: "buyer",
    },
  ];

  const updateUserType = (event) => {
    setUserType(event.target.value);
  }

  const onSubmit = (event) => {
    history.push({
      pathname: path.SIGN_UP,
      search: `?customer_type=${userType}&email=${emailAddress}`,  // query string
      state: {  // location state
        update: true,
      },
    });
  }


  return (
    <>
      <Grid item md={6} xs={12}>
        <Box spacing={4}>
          <Typography
            variant="h2"
            className="font-jost pt-1 text-heading font-bold mb-8"
            align="left"
          >
            Share solar within your community
          </Typography>
          <Typography
            variant="body1"
            className="font-open-sans text-subheading mb-6 leading-8"
            align="left"
          >
            Our platform connects with utility systems to enable peer-to-peer donations or sales between our users. You
            and your community get to benefit from the clean energy transition while we take care of the leg work.
          </Typography>
          <Box align="left">
            <RadioOptions
              value={userType}
              options={radioOptions}
              className="mb-6"
              onChange={updateUserType}
            ></RadioOptions>
          </Box>
          <LandingPageInputField
            placeholder="Please enter your email address"
            type="email"
            icon={<EmailOutlined/>}
            onChange={(event) => setEmailAddress(event.target.value)}
            onBtnClick={onSubmit}
          ></LandingPageInputField>
        </Box>
      </Grid>
      <Grid item md={6} xs={12} align="right" data-aos="zoom-in">
        <img
          src={Illustration}
          alt="logo"
          width={isMobile ? "100%" : "auto"}
        />
      </Grid>
    </>
  );
}

export default Welcome