import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { ResetPasswordSchema } from "./validation";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  title: {
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "47px",
    marginBottom: "20px",
  },
  caption: {
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "20px",
    marginBottom: "8px",
    color: "#BDBDBD",
  },
  list: {
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "20px",
    color: "#BDBDBD",
  },
  field: {
    height: "85px",
  },
  label: {
    color: "#212429",
    fontSize: "12px",
    fontWeight: "normal",
  },
  link: {
    textDecoration: "underline",
    fontSize: "12px",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "12px",
      lineHeight: "12px",
    },
  },
  button: {
    marginTop: "10px",
    backgroundColor: "#4776E6",
    minWidth: "165px",
    marginBottom: "10px",
  },
  subTitle: {
    color: "#BDBDBD",
    fontSize: "14px",
    fontWeight: "normal",
  },
});

const ResetPasswordForm = (props) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        password_1: "",
        password_2: "",
      }}
      validationSchema={ResetPasswordSchema}
      onSubmit={props.resetPassword}
    >
      {({
        values: { password_1, password_2 },
        touched,
        errors,
        setFieldValue,
      }) => (
        <>
          <Typography variant="h3" className={classes.title}>
            New password
          </Typography>
          <Typography variant="h5" className={classes.caption}>
            In order to protect your accaunt, make sure your new password has
            at least:
          </Typography>
          <ul className={classes.list}>
            <li>An uppercase character</li>
            <li>A lowercase character</li>
            <li>A digit/number</li>
            <li>A special character/punctuation</li>
          </ul>
          <Form>
            <Box className={classes.field}>
              <InputLabel htmlFor="username" className={classes.label}>
                Password
              </InputLabel>
              <TextField
                type="password"
                id="password_1"
                name="password_1"
                helperText={touched.password_1 ? errors.password_1 : ""}
                error={touched.password_1 && Boolean(errors.password_1)}
                value={password_1}
                onChange={(e) => {
                  setFieldValue("password_1", e.target.value);
                }}
                fullWidth
                variant="outlined"
                size="small"
                className={classes.textField}
              />
            </Box>
            <Box className={classes.field}>
              <InputLabel htmlFor="username" className={classes.label}>
                Repeat password
              </InputLabel>
              <TextField
                type="password"
                id="password_2"
                name="password_2"
                helperText={touched.password_2 ? errors.password_2 : ""}
                error={touched.password_2 && Boolean(errors.password_2)}
                value={password_2}
                onChange={(e) => {
                  setFieldValue("password_2", e.target.value);
                }}
                fullWidth
                variant="outlined"
                size="small"
                className={classes.textField}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                className={classes.button}
              >
                continue
              </Button>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
