import React from "react";
import {LockOutlined, Menu as MenuIcon} from "@material-ui/icons";
import {
  AppBar,
  IconButton,
  Button as Btn,
  Typography,
  Box,
  Grid,
  Container,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";

import PrimaryButton from "../../FormComponents/PrimaryButton";

import * as path from "../../../constants/routes";

const Header = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const goToHomePage = () => {
    const isHomePage = history.location.pathname === path.HOME
    if (isHomePage) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    } else {
      history.push(path.HOME)
    }
  }

  const goToSection = (sectionId) => {
    if (history.location.pathname !== path.HOME) {
      history.push({pathname: path.HOME, hash: sectionId})
    }
    let elem = document.getElementById(sectionId)
    if (elem) {
      elem.scrollIntoView({behavior: "smooth"})
    }
  }

  React.useEffect(() => {
    if (history.location.hash) {
      let elem = document.getElementById(history.location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    }
  }, [history.location,])

  return (
    <>
      <AppBar component="nav" elevation={0} className="bg-white" position="sticky" id="header">
        <Container>
          <Grid
            container
            direction="row"
            className="w-full sm:justify-between py-3"
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              edge="start"
              className="mr-2"
              style={{display: isMobile ? "" : "none"}}
            >
              <MenuIcon/>
            </IconButton>
            <Typography
              variant="h4"
              className="text-subheading font-jost font-bold cursor-pointer"
              component="div"
              onClick={goToHomePage}
            >
              SEP
            </Typography>
            <Box style={{display: isMobile ? "none" : "flex"}} className="gap-4" component="div">
              <Btn
                className="font-jost text-subheading px-4 normal-case"
                onClick={goToHomePage}
              >
                Home
              </Btn>
              <Btn
                className="font-jost text-subheading px-4 normal-case"
                onClick={() => goToSection("about-section")}
              >
                About
              </Btn>
              <Btn
                className="font-jost text-subheading px-4 normal-case"
                onClick={() => goToSection("service-section")}
              >
                Services
              </Btn>
              <PrimaryButton
                name={
                  <Box className="flex items-start" component="span">
                    <LockOutlined
                      style={{
                        height: "15px",
                        paddingTop: "1px",
                      }}
                    />
                    Sign In
                  </Box>
                }
                onClick={() => {
                  history.push("/login");
                }}
                padding="7px auto"
                textVariant="body2"
                width="auto"
              ></PrimaryButton>
            </Box>
          </Grid>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >

            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                className="font-jost text-subheading"
                onClick={goToHomePage}
              >
                Home
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                className="font-jost text-subheading"
                onClick={() => goToSection("about-section")}
              >
                About
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                className="font-jost text-subheading"
                onClick={() => goToSection("service-section")}
              >
                Services
              </Typography>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => history.push(path.SIGN_UP)}>
              <Typography
                className="text-secondary font-jost"
                component="span"
              >
                Sign In
              </Typography>
            </MenuItem>
          </Menu>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
