import React from "react";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { CLIENT_URL } from "../../constants/index";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  },
  background: {
    height: "120%",
    width: "432px",
    backgroundColor: "#EEF1F7",
    transform: "rotate(15deg)",
    position: "absolute",
    marginLeft: "135px",
  },
  content: {
    zIndex: 10,
    fontSize: "3rem",
  },
});

const UtilityApiCallback = (props) => {
  const classes = useStyles();
  const searchParams = new URLSearchParams(props.location.search);
  const referral = searchParams.get("referral");

  window.parent.postMessage(
    {
      event_id: "referral_message",
      data: { referral: referral },
    },
    CLIENT_URL //or "www.parentpage.com"
  );

  return (
    <Box className={classes.root}>
      <div className={classes.background}></div>
      <div className={classes.content}>
        <CircularProgress size={50} />
      </div>
    </Box>
  );
};

export default withRouter(UtilityApiCallback);
