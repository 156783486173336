import {Box, Typography} from "@material-ui/core";
import BannerImage from "../../../assets/Banner.png"

const Banner = (props) => {
  return (
    <>
      <Box>
        <Box className="relative">
          <img
            src={BannerImage}
            className="object-cover h-[30vh] w-full"
            alt="logo"
          />
          <Box
            className="flex justify-center items-center h-[30vh] bg-[#1f1e1eb3] w-full opacity-100 text-white absolute top-0 left-0">
            <Typography
              variant="h3"
              className="text-secondary font-jost font-bold"
            >
              {props.text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Banner;