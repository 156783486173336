import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = ({ styles }) => (
  <div style={styles}>
    <CircularProgress />
  </div>
);

export default Loader;
