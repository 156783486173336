import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ChangePasswordForm from "../Forms/ChangePasswordForm/ChangePasswordForm";
import {styled} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "325px",
  },
  dialogActions: {
    justifyContent: "center",
    padding: "24px",
  },
  dialogTitle: {
    textAlign: "center",
  },
  textTitle: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
}));

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px !important",
    padding: "2rem"
  }
})

const ModalChangePassword = (props) => {
  const classes = useStyles();

  const {handleClose, open, userId} = props;
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="ModalChangePassword"
      maxWidth="md"
    >
      <DialogTitle
        id="ModalChangePassword"
        onClose={handleClose}
      >
        <Typography className={classes.textTitle}>Change password</Typography>
      </DialogTitle>

      <Box component="div" className="p-9">
        <ChangePasswordForm handleClose={handleClose} userId={userId}/>
      </Box>
    </StyledDialog>
  );
};

export default ModalChangePassword;
