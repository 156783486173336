import { BACKEND_URL } from "../../constants/index";

export const GET_PROFILE = BACKEND_URL + "/api/users/me/?format=json";
export const LOGIN = BACKEND_URL + "/api/login/";
export const LOG_OUT = BACKEND_URL + "/api/auth/logout/";
export const CHANGE_PASSWORD = BACKEND_URL + "/api/auth/change-password/";
export const EDIT_USER = BACKEND_URL + "/api/auth/edit-user/";
export const FORGOT_PASSWORD = BACKEND_URL + "/api/auth/forgot-password/";
export const VERIFY_CODE = BACKEND_URL + "/api/auth/verify-password/";
export const CHANGE_FORGOT_PASSWORD =
  BACKEND_URL + "/api/auth/change-forgot-password/";
export const APPLY_DISCOUNT_CODE = BACKEND_URL + "/api/discount_codes/apply/";
export const UTILITY_ZONES = BACKEND_URL + "/api/utility_zones/";
export const SELLER_CODES = BACKEND_URL + "/api/seller_codes/";
export const LOAD_ZONES = BACKEND_URL + "/api/load_zones/";
export const SIGN_UP_SELLER = BACKEND_URL + "/api/signup_seller/";
export const SIGN_UP_BUYER = BACKEND_URL + "/api/signup_buyer/";
export const REGISTER_UTILITY = BACKEND_URL + "/api/register_utility/";
export const GET_PENDING_DEALS = BACKEND_URL + "/api/pending_deals/";
export const GET_BAYER_DEAL = BACKEND_URL + "/api/buyer_deal/";
export const GET_ACTIVE_DEALS = BACKEND_URL + "/api/active_deals/";
export const REGISTER_PAYMENT = BACKEND_URL + "/api/register_payment/";
export const ACCEPT_DEAL = BACKEND_URL + "/api/accept_deal/";
export const SAVED_MONEY_INFO = BACKEND_URL + "/api/saved_money_info/";
export const CHECK_PAYOUTS_ENABLED =
  BACKEND_URL + "/api/check_payouts_enabled/";
export const GET_LIST_TRANSACTION = BACKEND_URL + "/api/list-transaction/";
export const SELLER_REFRESH_STRIPE_URL =
  BACKEND_URL + "/api/refresh_url_seller/";
